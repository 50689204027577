toastr.options.closeButton = true;
toastr.options.timeOut = 6000;
toastr.options.positionClass = "toast-bottom-right";
toastr.options.progressBar = true;
toastr.options.preventDuplicates = false;

// angular send date in utc time, this is for override that behaviour
// tslint:disable-next-line:only-arrow-functions
// 'this' is important here, so cannot use arrow function
Date.prototype.toISOString = function () {
    let format = moment(this).format("YYYY-MM-DDTHH:mm:ss");
    return format;
};
